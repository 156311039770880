import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { FlamingoForm, FlamingoFormCreateUpdateDTO } from '@app/modules/flamingo/models/flamingo-form.model'
import { FlamingoAuthenticationService } from '@app/modules/shared/services/flamingo-auth.service'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { FlamingoFormsQuery } from '../models/flamingo-form-query.model'

@Injectable({
    providedIn: 'root'
})
export class FlamingoAPIService {
    private readonly baseApiUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}`
    private readonly flamingoApiUrl = `${environment.flamingoForm.baseUrl}/formTemplates`

    constructor(
        private queryBuilderService: QueryBuilderService,
        private flamingoAuthService: FlamingoAuthenticationService
    ) { }

    public getFlamingoForms({
        search = '',
        sortBy = '',
        currentPage = 1,
        itemPerPage = AppSettings.flamingoFormPageSize,
        isDesc = false
    }: FlamingoFormsQuery = {}): Observable<HttpResponse<FlamingoForm[]>> {
        return this.queryBuilderService
            .get<FlamingoForm[]>(
                this.baseApiUrl + '/FlamingoForms',
                { search, sortBy, currentPage, itemPerPage, isDesc }
            )
    }

    public getEquipmentFlamingoFormMapping(equipmentId: string): Observable<HttpResponse<FlamingoForm[]>> {
        return this.queryBuilderService
            .get<FlamingoForm[]>(this.baseApiUrl + `/Equipment(${equipmentId})/FlamingoForms`)
    }

    public linkFlamingoFormToEquipment(flamingoFormId: string, equipmentId: string): Observable<any> {
        return this.queryBuilderService
            .post(this.baseApiUrl + `/FlamingoForms(${flamingoFormId})/Equipment(${equipmentId})`)
    }
    public unlinkFlamingoFormFromEquipment(flamingoFormId: string, equipmentId: string): Observable<any> {
        return this.queryBuilderService
            .delete(this.baseApiUrl + `/FlamingoForms(${flamingoFormId})/Equipment(${equipmentId})`)
    }

    public createOrUpdateFlamingoForm(flamingoForm: FlamingoFormCreateUpdateDTO): Observable<HttpResponse<FlamingoForm>> {
        return this.queryBuilderService
            .post(this.baseApiUrl + '/FlamingoForms', flamingoForm)
    }

    public getFlamingoFormSchema(formId: string): Observable<any> {
        return this.queryBuilderService
            .getWithToken(this.flamingoApiUrl + '/' + formId, this.flamingoAuthService.flamingoToken$.value)
            .pipe(map(res => res.body))
    }
}
